var render = function () {
  var _vm$order, _vm$order2, _vm$order3, _vm$order3$quote, _vm$order3$quote$disp, _vm$order4, _vm$order5, _vm$order6, _vm$order7, _vm$order8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-order"
  }, [_vm.order ? _c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('Order')) + " - " + _vm._s((_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : _vm$order.invoiceNumber))]), _c('span', [!_vm.order.quote.dispatch.invoiceFile && _vm.order.public && _vm.order.status !== 'CANCELED' && _vm.order.quote.dispatch.status !== 'CANCELED' ? _c('v-btn', {
    staticClass: "text-none white--text mr-4 mb-2",
    attrs: {
      "loading": _vm.sendingInvoice,
      "color": "warning"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-upload")]), _vm._v(" " + _vm._s(_vm.$t('UploadPDF')) + " ")], 1) : _vm._e(), _c('input', {
    ref: "uploadPDF",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/pdf"
    },
    on: {
      "change": _vm.fileSelected
    }
  }), _c('a', {
    ref: "orderSupplierInvoicePDFRef",
    staticClass: "d-none",
    attrs: {
      "download": "Facture_".concat(_vm.order.invoiceNumber)
    }
  }), _vm.order.quote.dispatch.invoiceFile ? _c('v-btn', {
    staticClass: "text-none white--text mr-4 mb-2",
    attrs: {
      "loading": _vm.loadingSupplierOrderInvoicePDF,
      "color": "orange"
    },
    on: {
      "click": _vm.getSupplierOrderInvoice
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-file-pdf")]), _vm._v(" " + _vm._s(_vm.$t('DownloadMyInvoice')) + " ")], 1) : _vm._e(), _vm.order.quote.dispatch.invoiceFile && !_vm.order.public && _vm.order.status !== 'DELIVERED' ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "text-none white--text mr-4 mb-2",
          attrs: {
            "loading": _vm.loadingDeleteSupplierOrderInvoicePDF,
            "color": "error"
          },
          on: {
            "click": _vm.openDeleteSupplierOrderInvoicePDFDialog
          }
        }, on), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-trash")])], 1)];
      }
    }], null, false, 87241676)
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('DeleteMyInvoice')) + " ")])]) : _vm._e(), _c('a', {
    ref: "deliveryOrderPDFRef",
    staticClass: "d-none",
    attrs: {
      "download": "CMT_BL_".concat(_vm.order.invoiceNumber)
    }
  }), _vm.order.quote.dispatch.deliveryOrder ? _c('v-btn', {
    staticClass: "text-none white--text mr-4 mb-2",
    attrs: {
      "color": "orange",
      "loading": _vm.loadingDeliveryOrderPDF
    },
    on: {
      "click": _vm.getSupplierDeliveryOrderPdf
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-file-pdf")]), _vm._v(" " + _vm._s(_vm.$t('deliveryOrder')) + " ")], 1) : _vm._e(), _c('a', {
    ref: "orderPDFRef",
    staticClass: "d-none",
    attrs: {
      "download": "CMT_BC_".concat(_vm.order.invoiceNumber)
    }
  }), _c('v-btn', {
    staticClass: "text-none white--text mr-4 mb-2",
    attrs: {
      "color": "orange",
      "loading": _vm.loadingOrderPDF
    },
    on: {
      "click": _vm.getSupplierOrderPdf
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-file-pdf")]), _vm._v(" " + _vm._s(_vm.$t('purchaseOrder2')) + " ")], 1)], 1)]) : _vm._e(), _c('v-divider', {
    staticClass: "mt-5 mb-3"
  }), ((_vm$order2 = _vm.order) === null || _vm$order2 === void 0 ? void 0 : _vm$order2.status) === 'CANCELED' || ((_vm$order3 = _vm.order) === null || _vm$order3 === void 0 ? void 0 : (_vm$order3$quote = _vm$order3.quote) === null || _vm$order3$quote === void 0 ? void 0 : (_vm$order3$quote$disp = _vm$order3$quote.dispatch) === null || _vm$order3$quote$disp === void 0 ? void 0 : _vm$order3$quote$disp.status) === 'CANCELED' ? _c('v-alert', {
    staticClass: "text-h6 text-center",
    attrs: {
      "type": "info",
      "text": "",
      "outlined": "",
      "prominent": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ThisOrderHasBeenCanceled')) + " ")]) : _vm.order && _vm.order.billing && !_vm.order.public && _vm.order.status !== 'NEW' && (!_vm.order.quote.dispatch.invoiceFile || _vm.order.paymentStatus === 'WAITING') ? _c('v-alert', {
    attrs: {
      "type": "error",
      "text": "",
      "outlined": "",
      "prominent": ""
    }
  }, [!_vm.order.quote.dispatch.invoiceFile ? _c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('InvoiceFileMissing')) + " ")]), _c('v-btn', {
    staticClass: "text-none white--text ml-2",
    attrs: {
      "loading": _vm.sendingInvoice,
      "color": "warning"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('UploadPDF')) + " ")], 1)], 1) : _vm.order.paymentStatus === 'WAITING' ? _c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('PaymentNotValidatedYet')) + " ")]), _c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.openPaymentValidationDialog
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('ValidatePayment')) + " ")], 1)], 1) : _vm._e()]) : _vm._e(), _vm.order && !_vm.order.public && !_vm.order.quote.dispatch.invoiceFile && _vm.order.status === 'NEW' ? _c('PrivateOrderInvoice', {
    ref: "privateOrderInvoiceRef",
    attrs: {
      "order": _vm.order
    },
    on: {
      "pdfSelected": _vm.addSupplierOrderInvoicePDF,
      "startOrder": _vm.openStartOrderDialog
    }
  }) : _vm._e(), ((_vm$order4 = _vm.order) === null || _vm$order4 === void 0 ? void 0 : _vm$order4.status) !== 'NEW' && Object.keys(_vm.partsSerialNumbers).length > 0 ? _c('v-alert', {
    staticClass: "text-center align-center pl-12 primary--text",
    attrs: {
      "prominent": "",
      "color": "orange",
      "border": "left",
      "elevation": "2",
      "colored-border": "",
      "icon": "fas fa-info"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('PartsWithSerialNumberMessage1')) + " ")]), _vm.step <= 2 ? _c('v-row', {
    staticClass: "mb-0 mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('PartsWithSerialNumberMessage2')))]), _c('v-img', {
    staticClass: "ml-1",
    attrs: {
      "src": require("../../../public/img/icons/cubeBCM.png"),
      "alt": "cube CMT",
      "max-height": "28px",
      "max-width": "28px",
      "contain": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('PartsWithSerialNumberMessage3')))])], 1) : _vm._e()], 1) : _vm._e(), _c('v-card', {
    staticClass: "pa-5 py-6"
  }, [((_vm$order5 = _vm.order) === null || _vm$order5 === void 0 ? void 0 : _vm$order5.status) !== 'NEW' ? _c('v-col', [_vm.order ? _c('SupplierOrderStepper', {
    attrs: {
      "order": _vm.order,
      "estimated-dates": _vm.order.quote.dispatch.estimatedDates
    },
    on: {
      "updateStepper": _vm.update,
      "saveEstimatedDates": _vm.saveEstimatedDates
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.step === 6 ? _c('v-divider', {
    staticClass: "my-5"
  }) : _vm._e(), _vm.step >= 4 ? _c('div', {
    staticClass: "module-deliveryTracking mb-5",
    on: {
      "click": _vm.verifyCompletedDeliveryTracking
    }
  }, [_c('v-expansion-panels', {
    model: {
      value: _vm.expandedDeliveryTracking,
      callback: function callback($$v) {
        _vm.expandedDeliveryTracking = $$v;
      },
      expression: "expandedDeliveryTracking"
    }
  }, [_c('v-expansion-panel', [_c('div', {
    staticClass: "mb-0"
  }, [_c('v-expansion-panel-header', {
    staticClass: "d-flex justify-space-between"
  }, [_c('h5', {
    staticClass: "orange--text title col"
  }, [_c('v-icon', {
    staticClass: "mb-1 mr-3",
    attrs: {
      "small": "",
      "color": "orange"
    }
  }, [_vm._v(" fas fa-box-open ")]), _vm._v(" " + _vm._s(_vm.$t('DeliveryTracking')) + " "), _vm.order.quote.dispatch.deliveryOrder ? _c('v-chip', {
    staticClass: "ml-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('deliveryOrder:')) + " " + _vm._s(_vm.order.quote.dispatch.deliveryOrder) + " ")]) : _vm._e()], 1), _c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "text": "",
      "ripple": false,
      "color": "orange"
    },
    on: {
      "click": _vm.linkToDeliveryTrackingWebsite
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-external-link-alt ")]), _vm._v(" " + _vm._s(_vm.$t('ViewTracking')) + " ")], 1)], 1)], 1), _c('v-expansion-panel-content', [_c('v-divider', {
    staticClass: "pb-5"
  }), _vm.order ? _c('DeliveryTrackingV2', {
    ref: "myDeliveryTracking",
    attrs: {
      "readonly": _vm.step !== 4,
      "order": _vm.order
    },
    on: {
      "save": _vm.modifyDeliveryTracking,
      "supportMail": _vm.sendMailForOtherCarrier
    }
  }) : _c('v-alert', {
    staticClass: "mt-3 lighten-1",
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.deliveryTrackingErrorMessage) + " ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.order ? [_vm.supplier ? _c('v-expansion-panels', {
    staticClass: "mb-5",
    attrs: {
      "focusable": true
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-row', {
    staticClass: "orderInfo"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.supplier.address.country === 'FR' ? _c('p', {
    staticClass: "mt-4"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('totalPriceOrder')))]), _vm._v(" " + _vm._s(_vm.$n(_vm.order.quote.dispatch.purchase.totalAmount, 'currency', 'fr')) + " ")]) : _c('p', {
    staticClass: "mt-4"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('totalPriceOrderHT')))]), _vm._v(" " + _vm._s(_vm.$n(_vm.order.quote.dispatch.purchase.totalAmountWithoutVAT, 'currency', 'fr')) + " ")]), _c('p', {
    staticStyle: {
      "overflow-wrap": "anywhere"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('purchaseOrder')))]), _vm._v(" " + _vm._s(_vm.order.invoiceNumber) + " ")]), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('created:')))]), _vm._v(" " + _vm._s(_vm.$d(new Date(_vm.order.created), 'long')) + " ")]), _vm.order.quote.dispatch.deliveryOrder ? _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('deliveryOrder:')))]), _vm._v(" " + _vm._s(_vm.order.quote.dispatch.deliveryOrder) + " ")]) : _vm._e()]), _vm.order && _vm.order.quote.dispatch.estimatedDates ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.order.quote.dispatch.estimatedDates.production ? _c('p', {
    staticClass: "mt-4"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('productionDate:')))]), _vm._v(" " + _vm._s(_vm.$d(new Date(_vm.order.quote.dispatch.estimatedDates.production), 'short')) + " ")]) : _vm._e(), _vm.order.quote.dispatch.estimatedDates.sending ? _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('sendingDate:')))]), _vm._v(" " + _vm._s(_vm.$d(new Date(_vm.order.quote.dispatch.estimatedDates.sending), 'short')) + " "), _vm.step >= 2 && _vm.step < 5 ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.estimatedDatesDialog = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-pen ")])], 1) : _vm._e()], 1) : _vm._e()]) : _vm._e()], 1)], 1), _c('v-expansion-panel-content', {
    attrs: {
      "class-content": "px-0"
    }
  }, [_c('BeelseBill', {
    attrs: {
      "order": _vm.order,
      "custom-flat": true
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.order.quote.dispatch.items.length !== 0 ? _c('div', [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-cubes ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('OrderItems')) + " ")])], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.loadingTable ? [] : _vm.order.quote.dispatch.items,
      "item-key": "part._id",
      "expanded": _vm.expanded,
      "loading": _vm.loadingTable,
      "show-expand": "",
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      },
      "item-class": _vm.getItemRowClasses
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header.data-table-expand",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('ManufacturingInformation')))])];
      },
      proxy: true
    }, {
      key: "item.part",
      fn: function fn(_ref2) {
        var _item$part;

        var item = _ref2.item;
        return [(_item$part = item.part) !== null && _item$part !== void 0 && _item$part._id ? _c('div', {
          staticClass: "d-flex align-center"
        }, [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_vm.partsSerialNumbers[item.part._id] ? _c('v-img', _vm._g({
                staticClass: "pr-2 ml-n1",
                attrs: {
                  "src": require("../../../public/img/icons/cubeBCM.png"),
                  "alt": "cube CMT",
                  "max-height": "30px",
                  "max-width": "30px",
                  "contain": ""
                }
              }, on)) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('PartWithSerialNumber')))])]), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var _item$part2, _item$part2$attachmen;

              var on = _ref4.on;
              return [((_item$part2 = item.part) === null || _item$part2 === void 0 ? void 0 : (_item$part2$attachmen = _item$part2.attachments) === null || _item$part2$attachmen === void 0 ? void 0 : _item$part2$attachmen.length) > 0 ? _c('v-icon', _vm._g({
                staticClass: "pr-2 ml-n1",
                attrs: {
                  "color": "orange"
                }
              }, on), [_vm._v(" fas fa-paperclip ")]) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('partHasAttachments')))])]), _c('router-link', {
          staticClass: "text-decoration-none",
          staticStyle: {
            "height": "auto",
            "overflow-wrap": "anywhere"
          },
          attrs: {
            "to": {
              name: 'Part',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(_vm.order._id),
                customerUUID: "".concat(_vm.order.public ? 'BCM' : item.part.brand_id),
                partUUID: "".concat(item.part._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.part.name) + " ")]), item.canceled ? _c('span', {
          staticClass: "ml-2 text-h6 font-weight-bold error--text"
        }, [_vm._v(" [" + _vm._s(_vm.$t('Canceled').toUpperCase()) + "] ")]) : _vm._e()], 1) : _c('div', {
          staticClass: "d-flex align-center"
        }, [_c('span', {
          staticClass: "font-weight-medium font-italic"
        }, [_vm._v(" " + _vm._s(_vm.$t('PartUnavailable')) + " ")]), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on;
              return [_c('v-icon', _vm._g({
                staticClass: "ml-2",
                attrs: {
                  "small": ""
                }
              }, on), [_vm._v(" fas fa-question-circle ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('PartUnavailable2')) + " ")])])], 1)];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "ma-1"
        }, [_vm._v(" " + _vm._s(item.quantity) + " ")]), item.canceled ? _c('span', {
          staticClass: "ml-2 text-h6 font-weight-bold error--text"
        }, [_vm._v(" -> 0 ")]) : _vm._e()];
      }
    }, {
      key: "item.technology",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-chip', {
          staticClass: "ma-1"
        }, [_vm._v(" " + _vm._s(_vm.$t(item.manufacturingInformation.dnaTechnology)) + " ")])];
      }
    }, {
      key: "item.material",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('v-chip', {
          staticClass: "ma-1"
        }, [_vm._v(" " + _vm._s(item.manufacturingInformation.material) + " ")])];
      }
    }, {
      key: "item.sendToProduction",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref10) {
              var on = _ref10.on;
              return [item.sentToProduction ? _c('v-btn', _vm._g({
                staticClass: "ml-2",
                attrs: {
                  "color": "orange",
                  "to": {
                    name: 'BcmPartProduction',
                    params: {
                      supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                      partUUID: "".concat(item.part._id)
                    }
                  }
                }
              }, on), [_c('v-icon', {
                staticClass: "mr-1 white--text"
              }, [_vm._v(" fas fa-cog ")]), _c('v-icon', {
                staticClass: "white--text"
              }, [_vm._v(" fas fa-industry ")])], 1) : _vm._e()];
            }
          }], null, true)
        }, [_vm._v(" " + _vm._s(_vm.$t('manageProductionPart')) + " ")])];
      }
    }, {
      key: "item.download",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.part.downloadName ? _c('a', {
          ref: "downloadFileButton".concat(item.part._id),
          staticClass: "d-none",
          attrs: {
            "download": item.part.downloadName
          }
        }) : _vm._e(), item.part._id && !item.canceled ? _c('BtnMenu', {
          attrs: {
            "choices": _vm.partsDownloadChoices[item.part._id],
            "loading": _vm.partsFileLoading[item.part._id],
            "disabled": _vm.order && (_vm.order.status === 'CANCELED' || _vm.order.quote.dispatch.status === 'CANCELED' || !_vm.order.quote.dispatch.estimatedDates || !_vm.order.quote.dispatch.estimatedDates.production || !_vm.order.quote.dispatch.estimatedDates.sending) || _vm.partsFileLoading[item.part._id] || !!_vm.partsSerialNumbers[item.part._id] && _vm.partsSerialNumbers[item.part._id].serialNumber.csgStatus !== 'VALID',
            "color": "primary"
          },
          on: {
            "click": function click(extension) {
              _vm.getPartFile(item.part, extension);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(" fa fa-download ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Download')) + " ")])], 1) : _vm._e()];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref12) {
        var headers = _ref12.headers,
            item = _ref12.item;
        return [item.part._id ? _c('td', {
          staticClass: "grey lighten-4",
          attrs: {
            "colspan": headers.length
          }
        }, [item.manufacturingInformation.printer ? _c('p', {
          staticClass: "mt-4"
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('printerBrand')) + " ")]), _c('span', [_vm._v(" " + _vm._s(item.manufacturingInformation.printer.brand.replaceAll('_', ' ')) + " ")])]) : _vm._e(), item.manufacturingInformation.printer ? _c('p', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('printerModel')) + " ")]), _c('span', [_vm._v(" " + _vm._s(item.manufacturingInformation.printer.model.replaceAll('_', ' ')) + " ")])]) : _vm._e(), _c('p', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('material2')) + " ")]), _c('span', [_vm._v(" " + _vm._s(item.manufacturingInformation.material) + " ")])]), item.manufacturingInformation.manufacturing_parameters ? _c('p', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('manufacturingParameters')) + " ")]), _c('span', [_vm._v(" " + _vm._s(item.manufacturingInformation.manufacturing_parameters) + " ")])]) : _vm._e(), item.manufacturingInformation.finish ? _c('p', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('finish')) + " ")]), _c('span', [_vm._v(" " + _vm._s(item.manufacturingInformation.finish) + " ")])]) : _vm._e()]) : _vm._e()];
      }
    }], null, true)
  }), _vm.order.quote.dispatch.customLines.length ? _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Item')) + " ")])]), _c('th', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Quantity')) + " ")])])])]), _c('tbody', _vm._l(_vm.order.quote.dispatch.customLines, function (customLine, customLineIndex) {
          return _c('tr', {
            key: "cL-".concat(customLineIndex)
          }, [_c('td', [_c('span', [_vm._v(" " + _vm._s(customLine.description) + " ")])]), _c('td', [_c('span', [_vm._v(" " + _vm._s(customLine.quantity) + " ")])])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 1973981409)
  }) : _vm._e()], 1)], 1)], 1) : _vm._e()] : _vm._e()], 2), _vm.step === 3 ? _c('v-dialog', {
    attrs: {
      "primary-title": "",
      "max-width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialogPackageReadyForShipment,
      callback: function callback($$v) {
        _vm.dialogPackageReadyForShipment = $$v;
      },
      expression: "dialogPackageReadyForShipment"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('ValidationOfPackagePreparation')) + " ")]), _c('v-card-text', {
    staticClass: "mt-12"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "text-subtitle-1",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('NumberOfPackageForThisOrderText', {
        number: (_vm$order6 = _vm.order) === null || _vm$order6 === void 0 ? void 0 : _vm$order6.quote.dispatch.estimatedPackages
      }))
    }
  })])], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto pl-5"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('NumberOfPackageForThisOrder')) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    staticClass: "numberOfPackage",
    attrs: {
      "dense": "",
      "hide-details": "auto",
      "outlined": "",
      "min": "1",
      "type": "number"
    },
    model: {
      value: _vm.numberOfPackage,
      callback: function callback($$v) {
        _vm.numberOfPackage = $$v;
      },
      expression: "numberOfPackage"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialogPackageReadyForShipment = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('close')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_c('v-icon', [_vm._v("fas fa-box")]), _c('v-icon', {
    staticClass: "position-absolute white rounded-xl",
    staticStyle: {
      "left": "-8px",
      "bottom": "-5px"
    },
    attrs: {
      "color": "primary",
      "small": ""
    }
  }, [_vm._v(" fas fa-check-circle ")]), _vm._v(" " + _vm._s(_vm.$t('PackageReadyForShipment')) + " ")], 1)], 1)], 1)], 1) : _vm._e(), _vm.step === 1 ? _c('v-dialog', {
    attrs: {
      "primary-title": "",
      "max-width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialogForValidateNextStep2,
      callback: function callback($$v) {
        _vm.dialogForValidateNextStep2 = $$v;
      },
      expression: "dialogForValidateNextStep2"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('manufacturing')) + " ")]), _c('v-divider'), _c('v-card', {
    staticClass: "py-4 px-6",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reallyWantToGoNextStepManufacturing')) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialogForValidateNextStep2 = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('close')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('SendToManufacturing')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-right ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.step === 2 ? _c('v-dialog', {
    attrs: {
      "primary-title": "",
      "max-width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialogManufacturingValidation,
      callback: function callback($$v) {
        _vm.dialogManufacturingValidation = $$v;
      },
      expression: "dialogManufacturingValidation"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('ManufacturingValidation')) + " ")]), _c('v-divider'), _c('v-card', {
    staticClass: "py-4 px-6",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reallyWantToValidateManufacturing')) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialogManufacturingValidation = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('close')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ManufacturingValidationButton')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-right ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.step === 4 ? _c('v-dialog', {
    attrs: {
      "primary-title": "",
      "max-width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialogForValidateNextStep4,
      callback: function callback($$v) {
        _vm.dialogForValidateNextStep4 = $$v;
      },
      expression: "dialogForValidateNextStep4"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('delivery')) + " ")]), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card', {
    staticClass: "py-4 px-6",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reallyWantToGoNextStepDelivery')) + " ")]), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.dialogForValidateNextStep4 = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('close')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('SendTheOrder')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-right ")])], 1)], 1)], 1)], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "800"
    },
    model: {
      value: _vm.estimatedDatesDialog,
      callback: function callback($$v) {
        _vm.estimatedDatesDialog = $$v;
      },
      expression: "estimatedDatesDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('EstimatedDatesOrderPicker', {
    ref: "estimatedDatesOrderPickerDialog",
    attrs: {
      "estimated-dates": (_vm$order7 = _vm.order) === null || _vm$order7 === void 0 ? void 0 : _vm$order7.quote.dispatch.estimatedDates,
      "viewer": true
    },
    on: {
      "save": _vm.saveEstimatedDates
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.estimatedDatesDialog = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Close')) + " ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "persistent": _vm.paymentValidationLoading
    },
    model: {
      value: _vm.paymentValidationDialog,
      callback: function callback($$v) {
        _vm.paymentValidationDialog = $$v;
      },
      expression: "paymentValidationDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('PaymentValidation')) + " ")]), _c('v-card-text', [_c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('PaymentValidationText')) + " ")]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$order8 = _vm.order) === null || _vm$order8 === void 0 ? void 0 : _vm$order8.invoiceNumber) + ". ")])])]), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error",
      "disabled": _vm.paymentValidationLoading
    },
    on: {
      "click": _vm.closePaymentValidationDialog
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "color": "success",
      "loading": _vm.paymentValidationLoading
    },
    on: {
      "click": _vm.modifySupplierOrderPaymentStatus
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('ValidatePayment')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "persistent": _vm.startOrderLoading
    },
    model: {
      value: _vm.startOrderDialog,
      callback: function callback($$v) {
        _vm.startOrderDialog = $$v;
      },
      expression: "startOrderDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('StartOrder')) + " ")]), _c('v-card-text', [_c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('StartOrderText1')) + " ")])]), _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('StartOrderText2')) + " ")])])]), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error",
      "disabled": _vm.startOrderLoading
    },
    on: {
      "click": _vm.closeStartOrderDialog
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "color": "success",
      "loading": _vm.startOrderLoading
    },
    on: {
      "click": _vm.modifySupplierOrderStatus
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('StartOrder')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "450px"
    },
    model: {
      value: _vm.dialogDeleteSupplierOrderInvoicePDF,
      callback: function callback($$v) {
        _vm.dialogDeleteSupplierOrderInvoicePDF = $$v;
      },
      expression: "dialogDeleteSupplierOrderInvoicePDF"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('DeleteInvoicePDFTitle')) + " ")]), _c('v-card-text', [_c('p', [_vm._v(_vm._s(_vm.$t('DeleteInvoicePDFText1')))]), _c('p', [_vm._v(_vm._s(_vm.$t('DeleteInvoicePDFText2')))])]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeDeleteSupplierOrderInvoicePDFDialog
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary",
      "loading": _vm.loadingDeleteSupplierOrderInvoicePDF
    },
    on: {
      "click": _vm.deleteSupplierOrderInvoicePDF
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }