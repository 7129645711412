var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    staticClass: "component-privateOrderInvoice",
    attrs: {
      "type": "info",
      "prominent": "",
      "color": "info",
      "icon": "fas fa-file-invoice",
      "elevation": "2",
      "colored-border": "",
      "border": "left"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('DropZoneContainer', {
    attrs: {
      "color": "".concat(_vm.$vuetify.theme.defaults.light.success, "FF"),
      "bordered": false,
      "text": _vm.$t('DropFileHere'),
      "allowed-formats": ['pdf'],
      "allow-multiple": false,
      "block-if-error": true,
      "width": "90%",
      "height": "100%",
      "text-class": "text-h6",
      "icon": "fas fa-check-circle",
      "rounded": ""
    },
    on: {
      "drop": _vm.dropFile
    }
  }, [_c('input', {
    ref: "uploadPDF",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/pdf"
    },
    on: {
      "change": _vm.fileSelected
    }
  }), _c('v-card', {
    staticClass: "dropZoneCard v-btn--is-elevated",
    attrs: {
      "height": "200px",
      "width": "90%"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex align-center justify-center fill-height"
  }, [_c('div', {
    staticClass: "text-center"
  }, [!_vm.loading ? _c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-file-pdf ")]) : _c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-circle-notch fa-spin ")]), _c('p', {
    staticClass: "mb-0 mt-4 text-subtitle-2"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), !_vm.loading ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('SelectPDFFile')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('UploadInProgress')) + " ")])], 1)], 1)])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('InvoiceNotUploadedYet')) + " ")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('p', [_vm._v(" " + _vm._s(_vm.$t('StartOrderWithoutInvoice')) + " ")]), _c('p', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "large": "",
      "width": "100%",
      "color": "info"
    },
    on: {
      "click": _vm.startOrder
    }
  }, [_c('v-icon', {
    staticClass: "mr-4"
  }, [_vm._v(" fas fa-industry ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('StartOrderNow')) + " ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }