<template lang="html" src="./pageOrder.template.vue"></template>

<style lang="scss" src="./pageOrder.scss"></style>

<script>
import PrivateOrderInvoice from '../../components/privateOrderInvoice/PrivateOrderInvoice.vue';

import {
  ApiErrorParser,
  BeelseBill,
  BtnMenu,
  DeliveryTrackingV2,
  DownloadSupplierPartOriginalFile,
  DownloadSupplierOrderPartFile,
  EstimatedDatesOrderPicker,
  Steppers,
  SupplierOrderStepper,
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageOrder.i18n');

export default {
  name: 'PageOrder',
  components: {
    BeelseBill,
    BtnMenu,
    DeliveryTrackingV2,
    EstimatedDatesOrderPicker,
    PrivateOrderInvoice,
    Steppers,
    SupplierOrderStepper,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      order: null,
      partsSerialNumbers: {},
      partsDataCount: 0,
      supplier: null,
      manufacturingInformations: {},
      wizardItems: [],
      expanded: [],
      headers: [],
      loadingTable: true,
      step: 1,
      partsFileMetaData: {},
      deliveryTrackingInline: false,
      deliveryTrackingFormMode: null,
      deliveryTrackingComplete: false,
      deliveryTrackingIdsURL: null,
      deliveryTrackingErrorMessage: null,
      estimatedDatesDialog: false,
      expandedDeliveryTracking: null,
      validationStatus: false,
      dialogManufacturingValidation: false,
      dialogPackageReadyForShipment: false,
      dialogForValidateNextStep2: false,
      dialogForValidateNextStep4: false,
      loadingOrderPDF: false,
      orderPDFData: null,
      loadingDeliveryOrderPDF: false,
      deliveryOrderPDFData: null,
      loadingSupplierOrderInvoicePDF: false,
      orderSupplierInvoicePDFData: null,
      dialogDeleteSupplierOrderInvoicePDF: false,
      loadingDeleteSupplierOrderInvoicePDF: false,
      numberOfPackage: null,
      numberOfPackageValidate: false,
      partToSendToProduction: null,
      sendingInvoice: false,
      sendToProductionDialog: false,
      supplierOrderInvoiceResult: false,
      paymentValidationDialog: false,
      paymentValidationLoading: false,
      startOrderDialog: false,
      startOrderLoading: false,
      /**
       * Parts files
       */
      partsDownloadChoices: {},
      partsFileDataURL: {},
      partsFileLoading: {},
    };
  },
  created() {
    this.wizardItems = [
      this.$t('SCHEDULED'),
      this.$t('MANUFACTURING'),
      this.$t('MANUFACTURED'),
      this.$t('DELIVERY'),
      this.$t('DELIVERED'),
    ];
    this.getSupplierOrder();
    this.getSupplier();
  },
  updated() {
    if (this.estimatedDatesDialog) {
      const element = this.$refs.estimatedDatesOrderPickerDialog.$el.getElementsByClassName('v-card');
      element[0].classList.add('v-card--flat');
      element[0].classList.add('pa-0');
      const input = this.$refs.estimatedDatesOrderPickerDialog.$el.getElementsByClassName('v-input');
    
      if (this.step >= 2 ) {
        input[0].style.pointerEvents = 'none';
        input[0].style.opacity = '0.5';
      }
    }
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('part'),
        value: 'part',
      },
      {
        text: this.$t('quantity'),
        value: 'quantity',
        align: 'center',
      },
      {
        text: this.$t('technology'),
        value: 'technology',
        align: 'center',
      },
      {
        text: this.$t('material'),
        value: 'material',
        align: 'center',
      },
      // {
      //   text: this.$t('productionModule'),
      //   value: 'sendToProduction',
      //   align: 'center',
      //   sortable: false,
      // },
      {
        text: this.$t('Download'),
        value: 'download',
        align: 'center',
        sortable: false,
      },
      { 
        text: this.$t('ManufacturingInformation'),
        value: 'data-table-expand',
        align: 'center',
        sortable: false,
        width: '200px'
      },
    ];
  },
  methods: {
    updateItemDownloadChoices(item) {
      this.partsDownloadChoices[item.part._id] = [
        {
          text: '.3MF',
          value: '3mf'
        }
      ];

      if(item.manufacturingInformation.dnaMaterial === 'cb_pa_12' && item.manufacturingInformation.dnaTechnology === 'MJF') {
        /**
         * Don't add any other download file format
         */
      } else {

        this.partsDownloadChoices[item.part._id].splice(0,0,{ 
          text: '.STL',
          value: 'stl'
        });

        if(item.part && item.part.originalFile?.extension && !this.partsSerialNumbers[item.part._id]) {
          this.partsDownloadChoices[item.part._id].splice(0,0,{ 
            text: `.${item.part.originalFile.extension.toUpperCase()}`,
            value: item.part.originalFile.extension
          });

        }
      }
    },
    verifyCompletedDeliveryTracking() {
      if (this.step >= 4 && this.order.deliveryTracking) {
        let testDeliveryTrackingComplete = false;
        this.order.deliveryTracking.forEach((deliveryTracking) => {
          if (deliveryTracking.carrier && deliveryTracking.trackingNumber) {
            testDeliveryTrackingComplete = true;
          }
        });
        this.deliveryTrackingComplete = testDeliveryTrackingComplete;
      }
    },
    /*
     * GET SUPPLIER ORDER
     */
    getSupplierOrder() {
      this.partToSendToProduction = null;
      this.loadingTable = true;
      this.manufacturingInformations = {};
      this.loadingTable = true;
      /**
       * GET ORDER
       */
      this.$apiInstance
        .getSupplierOrder(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID
        )
        .then(
          (supplierOrderData) => {
            /**
             * Set the id of the part as id for uniq row in the datatable
             */
            supplierOrderData.itemsSerialNumbers.forEach((itemSerialNumber) => {
              this.partsSerialNumbers[itemSerialNumber.part] = {
                code: itemSerialNumber.serialNumberCode,
                serialNumber: itemSerialNumber.serialNumber,
              };
            });
            supplierOrderData.quote.dispatch.items.forEach((item) => {
              this.partsFileMetaData[item.part._id] = item.part.file;
              this.updateItemDownloadChoices(item);
              if (
                supplierOrderData.status === 'SCHEDULED' ||
                supplierOrderData.status === 'MANUFACTURING'
              ) {
                this.expanded.push(item.part._id);
              }

              if (this.partsSerialNumbers[item.part._id]) {
                item.part['downloadName'] =
                  item.part?.name +
                  '_' +
                  this.partsSerialNumbers[item.part?._id].code +
                  '.' + item.part?.file?.extension;
              } else {
                item.part['downloadName'] =
                  item.part?.name + '.' + item.part?.file?.extension;
              }
            });
            this.order = supplierOrderData;
            /**
             * GET DELIVERY TRACKING INFORMATIONS
             */
            if (
              this.order.quote.dispatch.deliveryTracking &&
              this.order.quote.dispatch.deliveryTracking.length === this.order.quote.dispatch.packageNumber
            ) {
              this.validationStatus = true;
              this.deliveryTrackingFormMode = 'VIEWER';
              this.deliveryTrackingInline = true;
            } else {
              if (
                this.order.quote.dispatch.deliveryTracking &&
                this.order.quote.dispatch.deliveryTracking.length === 0
              ) {
                this.deliveryTrackingFormMode = 'EDITOR';
              }
              if (this.order.quote.dispatch.packageNumber && this.order.quote.dispatch.packageNumber > 100) {
                this.deliveryTrackingErrorMessage = this.$t(
                  'deliveryTrackingErrorMessage'
                );
              } else if (!this.order.quote.dispatch.packageNumber) {
                this.deliveryTrackingErrorMessage = this.$t('noPackageNumber');
              }
              this.validationStatus = false;
              this.deliveryTrackingInline = false;
            }
            if (!this.order.quote.dispatch.packageNumber) {
              this.numberOfPackage = this.order.quote.dispatch.estimatedPackages;
            }

            this.currentOrderStatus();
            this.loadingTable = false;
          },
          (error) => {
            /**
             * ERROR GET ORDER
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * GET SUPPLIER (For the address)
     */
    getSupplier() {
      this.$apiInstance.getSupplier(this.$route.params.supplierUUID).then(
        (supplierData) => {
          this.supplier = supplierData;
        },
        (error) => {
          ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * GET PART FILE
     */
    getPartFile(part, extension = null) {

      extension = extension || 'stl';

      this.$set(this.partsFileLoading, part._id, true);

      const button = this.$refs[`downloadFileButton${part._id}`];
      let splitName = part.downloadName.split('.');
      splitName.pop();
      splitName.join('.');
      splitName += '.' + extension;
      part.downloadName = splitName;

      if(!this.partsFileDataURL[part._id]) {
        this.partsFileDataURL[part._id] = {};
      }

      if (!this.partsFileDataURL[part._id][extension]) {

        let promise, req = null;

        if(['step', 'obj', 'iges'].includes(extension)) {
          [promise, req] = DownloadSupplierPartOriginalFile.downloadSupplierPartOriginalFile(
            this.$apiInstance,
            this.$route.params.supplierUUID,
            part._id,
          );
        } else {
          [promise, req] = DownloadSupplierOrderPartFile.downloadSupplierOrderPartFile(
            this.$apiInstance,
            this.$route.params.supplierUUID,
            this.$route.params.orderUUID,
            part._id,
            extension
          );
        }

        this.$downloadProgress.addDownload(req, part.downloadName, promise);
        promise.then((response) => {
          const byteArray = new Uint8Array(response.buffer);
          const blob = new Blob([byteArray], {
            type: 'application/octet-stream',
          });
          /**
           * Using $set to make it reactive
           */
          this.partsFileDataURL[part._id][extension] = URL.createObjectURL(blob);
          button.href = this.partsFileDataURL[part._id][extension];
          this.$set(this.partsFileLoading, part._id, false);
          setTimeout(() => {
            button.click();
          }, 250);
          
        });
      } else {
        button.href = this.partsFileDataURL[part._id][extension];
        setTimeout(() => {
          button.click();
          this.$set(this.partsFileLoading, part._id, false);
        }, 1000);
      }
    },
    /**
     * GET SUPPLIER ORDER INVOICE
     */
    getSupplierOrderInvoice() {
      if(!this.orderSupplierInvoicePDFData) {
        this.supplierOrderInvoiceResult = false;
        this.loadingSupplierOrderInvoicePDF = true;
        this.$apiInstance
          .getSupplierOrderInvoicePDF(
            this.$route.params.supplierUUID,
            this.$route.params.orderUUID
          )
          .then((result) => {
            if (result.data !== '') {
              this.sendingInvoice = false;
              this.orderSupplierInvoicePDFData = result.data;
              this.$refs.orderSupplierInvoicePDFRef.href = `data:application/pdf;base64,${this.orderSupplierInvoicePDFData}`;
              setTimeout(() => {
                this.$refs.orderSupplierInvoicePDFRef.click();
              }, 250);
            } else {
              this.sendingInvoice = false;
            }
          })
          .catch((error) => {
            this.$notification.notify('DANGER', ApiErrorParser.parse(error));
            this.sendingInvoice = false;
          })
          .finally(() => {
            this.supplierOrderInvoiceResult = true;
            this.loadingSupplierOrderInvoicePDF = false;
          });
      } else {
        this.$refs.orderSupplierInvoicePDFRef.click();
      }
    },

    /**
     *  CURRENT ORDER STATUS
     */
    currentOrderStatus() {
      if (this.order.quote.dispatch.status === 'SCHEDULED') {
        if (
          this.order.quote.dispatch.estimatedDates &&
          this.order.quote.dispatch.estimatedDates.production &&
          this.order.quote.dispatch.estimatedDates.sending
        ) {
          this.step = 1;
        } else {
          this.step = 0;
        }
      }
      if (this.order.quote.dispatch.status === 'MANUFACTURING') {
        this.step = 2;
      }
      if (this.order.quote.dispatch.status === 'MANUFACTURED') {
        this.step = 3;
      }
      if (this.order.quote.dispatch.status === 'MANUFACTURED' && this.order.quote.dispatch.packageNumber) {
        this.step = 4;
        this.expandedDeliveryTracking = 0;
      }
      if (this.order.quote.dispatch.status === 'DELIVERY') {
        this.step = 5;
        this.expandedDeliveryTracking = null;
      }
      if (this.order.quote.dispatch.status === 'DELIVERED') {
        this.step = 6;
      }
    },
    /**
     * NEXT STEP
     */
    nextStep() {
      switch (this.step) {
      case 1:
        this.dialogForValidateNextStep2 = false;
        this.manufacturingStatus();
        break;
      case 2:
        this.dialogManufacturingValidation = false;
        this.manufacturingValidation();
        break;
      case 3:
        this.dialogPackageReadyForShipment = false;
        this.saveNumberOfPackages();
        break;
      case 4:
        this.dialogForValidateNextStep4 = false;
        this.deliveryStatus();
        break;
      case 5:
        this.deliveredStatus();
        break;
      default:
        break;
      }
    },
    /**
     * MANUFACTURING STATUS
     */
    manufacturingStatus() {
      const modifySupplierOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
        'MANUFACTURING'
      );
      this.$apiInstance
        .modifySupplierOrderDispatchStatus(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID,
          modifySupplierOrderDispatchStatusBody
        )
        .then(
          () => {
            this.getSupplierOrder();
            this.$notification.notify('SUCCESS', this.$t('statusUpdated'));
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER DISPATCH STATUS
             */
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    manufacturingValidation() {
      const modifySupplierOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
        'MANUFACTURED'
      );
      this.$apiInstance
        .modifySupplierOrderDispatchStatus(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID,
          modifySupplierOrderDispatchStatusBody
        )
        .then(
          () => {
            this.getSupplierOrder();
            this.$notification.notify('SUCCESS', this.$t('statusUpdated'));
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER DISPATCH STATUS
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    saveEstimatedDates(typeOfDate, date) {
      const estimatedDates = this.order.quote.dispatch.estimatedDates
        ? this.order.quote.dispatch.estimatedDates
        : {};
      estimatedDates[typeOfDate] = new Date(date);
      this.$apiInstance
        .changeOrderDispatchEstimatedDates(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID,
          estimatedDates
        )
        .then(
          () => {
            this.getSupplierOrder();
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER DISPATCH PACKAGE NUMBER
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    /**
     * MANUFACTURED STATUS
     */
    saveNumberOfPackages() {
      if (
        !Number.isInteger(Number(this.numberOfPackage)) ||
        Number(this.numberOfPackage) <= 0
      ) {
        this.$notification.notify('DANGER', this.$t('invalidPackage'));
      } else {
        const numberPackage = Number(this.numberOfPackage);
        const changeOrderDispatchPackageNumberBySupplierBody = new this.$BcmModel.ChangeOrderDispatchPackageNumberBySupplierBody(
          numberPackage
        );
        this.$apiInstance
          .changeOrderDispatchPackageNumberBySupplier(
            this.$route.params.supplierUUID,
            this.$route.params.orderUUID,
            changeOrderDispatchPackageNumberBySupplierBody
          )
          .then(
            () => {
              this.getSupplierOrder();
            },
            (error) => {
              /**
               * ERROR CHANGE ORDER DISPATCH PACKAGE NUMBER
               */
              this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    /**
     * DELIVERY STATUS
     */
    deliveryStatus() {
      const modifySupplierOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
        'DELIVERY'
      );
      this.$apiInstance
        .modifySupplierOrderDispatchStatus(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID,
          modifySupplierOrderDispatchStatusBody
        )
        .then(
          () => {
            this.getSupplierOrder();
            this.$notification.notify('SUCCESS', this.$t('statusUpdated'));
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER DISPATCH STATUS
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    /**
     * DELIVERED STATUS
     */
    deliveredStatus() {
      const modifySupplierOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
        'DELIVERED'
      );
      this.$apiInstance
        .modifySupplierOrderDispatchStatus(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID,
          modifySupplierOrderDispatchStatusBody
        )
        .then(
          () => {
            this.getSupplierOrder();
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER DISPATCH STATUS
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    /**
     * SWITCH MODE FOR EDITING
     */
    switchModeToEditor() {
      this.deliveryTrackingFormMode = 'EDITOR';
      this.deliveryTrackingInline = false;
    },
    sendMailForOtherCarrier(trackingNumber, carrier) {
      this.$apiInstance.sendSupportEmailTicket(
        {
          subject: `[CMT New Carrier] [${this.$t('Order') + ' ' + this.order.invoiceNumber}]`, 
          body: this.$t('NewCarrierSupportMailBody', {supplier: this.$appConfig && this.$appConfig.user ? `${this.$appConfig.user.firstname} ${this.$appConfig.user.lastname}` : 'A supplier',orderNumber: this.order.quickbooksInvoicePurchaseOrder, trackingNumber, carrier})
        }
      );
    },
    /**
     * SAVE DELIVERY TRACKING
     */
    modifyDeliveryTracking(deliveryTracking) {
      this.$apiInstance
        .changeOrderDispatchTrackingNumbers(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID,
          deliveryTracking
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',  this.$tc('TrackingNumberUpdated', deliveryTracking.length));
            this.getSupplierOrder();
          },
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    cancel() {
      this.order.deliveryTracking = [];
      this.getSupplierOrder();
    },
    linkToDeliveryTrackingWebsite() {
      this.deliveryTrackingUrl = this.$refs.myDeliveryTracking.getURL();
      window.open(this.deliveryTrackingUrl);
    },
    getSupplierOrderPdf() {
      /**
       * GET ORDER PDF
       */
      if(!this.orderPDFData) {
        this.loadingOrderPDF = true;
        this.$apiInstance
          .getSupplierOrderPDF(
            this.$route.params.supplierUUID,
            this.$route.params.orderUUID,
            {lang: this.$userLocale}
          )
          .then(
            (pdf) => {
              this.orderPDFData = pdf.data;
              this.$refs.orderPDFRef.href = `data:application/pdf;base64,${this.orderPDFData}`;
              setTimeout(() => {
                this.$refs.orderPDFRef.click();
              }, 250);
            },
            (error) => {
              /**
               * ERROR GET QUOTE PDF
               *
               * Component BeelseNotifications used
               */
              this.$notification.notify('DANGER', ApiErrorParser.parse(error));
            }
          )
          .finally(() => {
            this.loadingOrderPDF = false;
          });
      } else {
        this.$refs.orderPDFRef.click();
      }
    },
    getSupplierDeliveryOrderPdf() {
      /**
       * GET DELIVERY ORDER PDF
       */
      if(!this.deliveryOrderPDFData) {
        this.loadingDeliveryOrderPDF = true;
        this.$apiInstance
          .getSupplierDeliveryOrderPDF(
            this.$route.params.supplierUUID,
            this.$route.params.orderUUID,
            {lang: this.$userLocale}
          )
          .then(
            (pdf) => {
              this.deliveryOrderPDFData = pdf.data;
              this.$refs.deliveryOrderPDFRef.href = `data:application/pdf;base64,${this.deliveryOrderPDFData}`;
              setTimeout(() => {
                this.$refs.deliveryOrderPDFRef.click();
              }, 250);
            },
            (error) => {
              /**
               * ERROR GET QUOTE PDF
               */
              this.$notification.notify('DANGER', ApiErrorParser.parse(error));
            }
          )
          .finally(() => {
            this.loadingDeliveryOrderPDF = false;
          });
      } else {
        this.$refs.deliveryOrderPDFRef.click();
      }
    },
    openFileSelector() {
      const fileSelector = this.$refs.uploadPDF;
      fileSelector.value = '';
      fileSelector.click();
    },
    fileSelected() {
      this.sendingInvoice = true;
      const fileSelector = this.$refs.uploadPDF;
      if (fileSelector.value && fileSelector.files.length > 0) {
        const pdf = fileSelector.files[0];
        const splittedFileName = pdf.name.split('.');
        if (
          splittedFileName.length > 0 &&
          splittedFileName[splittedFileName.length - 1].toLocaleLowerCase() ===
          'pdf'
        ) {
          const fileReader = new FileReader();
          fileReader.addEventListener('loadend', () => {
            const base64PDF = fileReader.result.split('base64,')[1];
            const opts = {
              data: base64PDF, // Pdf | Invoice PDF file to add
            };
            this.$apiInstance
              .addSupplierOrderInvoicePDF(
                this.$route.params.supplierUUID,
                this.$route.params.orderUUID,
                opts
              )
              .then(() => {
                this.$notification.notify('SUCCESS', this.$t('InvoiceAdded'));
                if(!this.order.public && this.order.status === 'NEW') {
                  this.modifySupplierOrderStatus();
                }
              }, (error) => {
                this.$notification.notify('ERROR', ApiErrorParser.parse(error));
              })
              .finally(() => {
                this.sendingInvoice = false;
                this.getSupplierOrder();
              });
          });
          fileReader.readAsDataURL(pdf);
        }
      }
    },
    addSupplierOrderInvoicePDF(pdf) {
      const opts = {
        data: pdf,
      };
      this.$apiInstance
        .addSupplierOrderInvoicePDF(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID,
          opts
        )
        .then(() => {
          this.$notification.notify('SUCCESS', this.$t('InvoiceAdded'));
          if(!this.order.public && this.order.status === 'NEW') {
            this.modifySupplierOrderStatus();
          }
        }, (error) => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        })
        .finally(() => {
          this.$refs.privateOrderInvoiceRef?.stopLoading();
          this.getSupplierOrder();
        });
    },
    openDeleteSupplierOrderInvoicePDFDialog() {
      this.dialogDeleteSupplierOrderInvoicePDF = true;
    },
    closeDeleteSupplierOrderInvoicePDFDialog() {
      this.dialogDeleteSupplierOrderInvoicePDF = false;
    },
    deleteSupplierOrderInvoicePDF() {
      this.loadingDeleteSupplierOrderInvoicePDF = true;
      this.$apiInstance.deleteSupplierOrderInvoicePDF(
        this.$route.params.supplierUUID,
        this.$route.params.orderUUID
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('InvoiceHasBeenDeleted'));
        this.dialogDeleteSupplierOrderInvoicePDF = false;
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loadingDeleteSupplierOrderInvoicePDF = false;
        this.getSupplierOrder();
      });
    },
    modifySupplierOrderStatus() {
      this.startOrderLoading = true;
      const modifySupplierOrderStatusBody = new this.$BcmModel.ModifySupplierOrderStatusBody('INPROGRESS');
      this.$apiInstance.modifySupplierOrderStatus(
        this.$route.params.supplierUUID,
        this.$route.params.orderUUID,
        modifySupplierOrderStatusBody
      ).then(() => {
        this.closeStartOrderDialog();
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.startOrderLoading = false;
        this.getSupplierOrder();
      });
    },
    modifySupplierOrderPaymentStatus() {
      this.paymentValidationLoading = true;
      const modifySupplierOrderPaymentStatusBody = new this.$BcmModel.ModifySupplierOrderPaymentStatusBody('PAID');
      this.$apiInstance.modifySupplierOrderPaymentStatus(
        this.$route.params.supplierUUID,
        this.$route.params.orderUUID,
        modifySupplierOrderPaymentStatusBody
      ).then(() => {
        this.closePaymentValidationDialog();
        this.$notification.notify('SUCCESS', this.$t('PaymentHasBeenValidated'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.paymentValidationLoading = false;
        this.getSupplierOrder();
      });
    },
    sendPartToProduction() {
      const partUUID = this.partToSendToProduction.part._id;
      this.partToSendToProduction.sendingToProduction = true;
      const self = this;
      this.$apiInstance
        .submitSupplierOrderPartToProduction(
          this.$route.params.supplierUUID,
          this.$route.params.orderUUID,
          partUUID
        )
        .then(
          (part) => {
            this.$notification.notify('SUCCESS',  this.$t('PartSentToProduction', { partName: part.name }));
          
          },
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.partToSendToProduction.sendingToProduction = false;  
          this.sendToProductionDialog = false;
          self.getSupplierOrder();
        });
    },
    closePaymentValidationDialog() {
      this.paymentValidationDialog = false;
    },
    openPaymentValidationDialog() {
      this.paymentValidationDialog = true;
    },
    closeStartOrderDialog() {
      this.startOrderDialog = false;
    },
    openStartOrderDialog() {
      this.startOrderDialog = true;
    },
    update(typeOfEvent) {
      switch (typeOfEvent) {
      case 'SendToManufacturing':
        this.dialogForValidateNextStep2 = true;
        break;

      case 'ManufacturingValidation':
        this.dialogManufacturingValidation = true;
        break;

      case 'PackageReadyForShipment':
        this.dialogPackageReadyForShipment = true;
        break;

      case 'DeliveryConfirmation':
        this.nextStep();
        break;

      case 'SendOrder':
        this.dialogForValidateNextStep4 = true;
        break;

      default:
        break;
      }
    },
    getItemRowClasses(item) {
      if(item.canceled) {
        return 'canceled-row';
      }
      return '';
    }
  },
};
</script>
