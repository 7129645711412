<template lang="html" src="./privateOrderInvoice.template.vue"></template>

<style lang="scss" src="./privateOrderInvoice.scss"></style>

<script>
import { DropZoneContainer } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./privateOrderInvoice.i18n');

export default {
  name: 'PrivateOrderInvoice',
  components: {
    DropZoneContainer
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      enabled: true,
      loading: false,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    fileSelected() {
      const fileSelector = this.$refs.uploadPDF;
      if(fileSelector?.files?.length) {
        const pdf = fileSelector.files[0];
        const splittedFileName = pdf.name.split('.');

        if(splittedFileName[splittedFileName.length - 1] !== 'pdf') {
          this.$notification.notify('ERROR', this.$t('WrongFileFormat'));
          return;
        }

        this.readAndSubmitFile(pdf);
      }
    },
    openFileSelector() {
      const fileSelector = this.$refs.uploadPDF;
      fileSelector.value = '';
      fileSelector.click();
    },
    dropFile(files) {
      if(files.length) {
        this.readAndSubmitFile(files[0]);
      }
    },
    readAndSubmitFile(file) {
      this.startLoading();
      const fileReader = new FileReader();
      fileReader.addEventListener('loadend', () => {
        const base64PDF = fileReader.result.split('base64,')[1];
        this.$emit('pdfSelected', base64PDF);
      });
      fileReader.readAsDataURL(file);
    },
    startOrder() {
      this.$emit('startOrder');
    }
  }
};

</script>